<template>
  <footer class="footer full-width-secondary-bg">
    <div class="footer__blocks">
      <div class="footer__block">
        <text-logo />
      </div>
      <div class="footer__block footer__block--top-margin">
        <h2>{{ $t('contact_options') }}</h2>
        <div class="footer__contact-options">
          <a
            :href="whatsAppContactLink"
            class="footer__contact-option"
            target="_blank"
            aria-label="WhatsApp"
          ><icon name="ic:baseline-whatsapp" /></a>
          <a
            :href="emailContactLink"
            class="footer__contact-option"
            target="_blank"
            aria-label="E-mail"
          ><icon name="ic:outline-email" /></a>
          <a
            href="https://www.facebook.com/people/Momentshareio/61550916671583/"
            class="footer__contact-option"
            target="_blank"
            aria-label="Facebook"
          ><icon name="ic:baseline-facebook" /></a>
          <a
            href="https://www.instagram.com/momentshare_io/"
            class="footer__contact-option"
            target="_blank"
            aria-label="Instagram"
          ><icon name="ph:instagram-logo" /></a>
        </div>
      </div>
      <div class="footer__block footer__block--top-margin">
        <h2>{{ $t('useful_links') }}</h2>
        <nuxt-link class="footer__link" :to="aboutLink">
          {{ $t('about_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="helpLink">
          {{ $t('help_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="termAndConditionsLink">
          {{ $t('terms_and_conditions') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="privacyPolicyLink">
          {{ $t('privacy_policy') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="accountLink">
          {{ $t('my_account') }}
        </nuxt-link>
        <client-only>
          <button
            v-if="isAuthenticated"
            class="footer__link"
            @click="logout"
          >
            {{ $t('logout') }}
          </button>
        </client-only>
      </div>
      <div class="footer__block footer__block--top-margin">
        <h2>{{ $t('perfect_for') }}</h2>
        <nuxt-link class="footer__link" :to="contentPageWeddingLink">
          {{ $t('wedding_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="contentPageWeddingAnniversaryLink">
          {{ $t('wedding_anniversary_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="contentPageCompanyOutingLink">
          {{ $t('company_outing_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="contentPageBirthdayLink">
          {{ $t('birthday_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="contentPageSportEventLink">
          {{ $t('sport_event_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="contentPageVacationLink">
          {{ $t('vacation_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="contentPageChristmasLink">
          {{ $t('christmas_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="contentPageEndOfYearLink">
          {{ $t('end_of_year_link_text') }}
        </nuxt-link>
        <nuxt-link class="footer__link" :to="contentPageFamilyGatheringLink">
          {{ $t('family_gathering_link_text') }}
        </nuxt-link>
      </div>
    </div>
    <div class="footer__bottom">
      Copyright © <text-logo class="footer__logo--small" />
    </div>
  </footer>
</template>

<script setup>
  import { ROUTES } from '~/lib/constants/routes'
  import { EMAIL_CONTACT_LINK, WHATSAPP_CONTACT_LINK } from '~/lib/constants/contact-options'
  import { useUserStore } from '~/stores/user'
  import { storeToRefs } from 'pinia'
  import { useLinks } from '~/lib/composables/links'

  const localePath = useLocalePath()
  const userStore = useUserStore()
  const { isAuthenticated } = storeToRefs(userStore)
  const { goToHomepage } = useLinks()
  const { $userService } = useNuxtApp()

  const whatsAppContactLink = WHATSAPP_CONTACT_LINK
  const emailContactLink = EMAIL_CONTACT_LINK
  const termAndConditionsLink = localePath(ROUTES.TERMS_AND_CONDITIONS)
  const privacyPolicyLink = localePath(ROUTES.PRIVACY_POLICY)
  const accountLink = localePath(ROUTES.ACCOUNT)
  const helpLink = localePath(ROUTES.HELP_FAQ)
  const aboutLink = localePath(ROUTES.ABOUT)
  const contentPageWeddingLink = localePath(ROUTES.CONTENT_PAGE_WEDDING)
  const contentPageWeddingAnniversaryLink = localePath(ROUTES.CONTENT_PAGE_WEDDING_ANNIVERSARY)
  const contentPageCompanyOutingLink = localePath(ROUTES.CONTENT_PAGE_COMPANY_OUTING)
  const contentPageBirthdayLink = localePath(ROUTES.CONTENT_PAGE_BIRTHDAY)
  const contentPageChristmasLink = localePath(ROUTES.CONTENT_PAGE_CHRISTMAS)
  const contentPageEndOfYearLink = localePath(ROUTES.CONTENT_PAGE_END_OF_YEAR)
  const contentPageSportEventLink = localePath(ROUTES.CONTENT_PAGE_SPORT_EVENT)
  const contentPageVacationLink = localePath(ROUTES.CONTENT_PAGE_VACATION)
  const contentPageFamilyGatheringLink = localePath(ROUTES.CONTENT_PAGE_FAMILY_GATHERING)

  async function logout() {
    await goToHomepage()

    $userService.logout()
  }
</script>
<style lang="scss">
  .footer {
    padding: 0 24px 24px 24px;
    width: 100%;
    display: flex;
    margin-top: 100px;
    background: var(--color-primary);
    flex-direction: column;
    border: 1px solid black;

    @include breakpoint(md) {
      margin-top: 150px;
    }
  }

  .footer__blocks {
    max-width: 1280px;
    width: 100%;
    padding: 20px 28px 0 28px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .footer__block {
    display: flex;
    flex-direction: column;
    color: var(--color-lightest);
    margin-bottom: var(--spacing-default);

    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }

  .footer__link {
    margin-top: 6px;
    transition: all .2s ease-in-out;
  }

  .footer__link:hover {
    color: var(--color-secondary)
  }

  .footer__block--top-margin {
    margin-top: var(--spacing-default);
  }

  .footer__contact-options {
    display: flex;
    gap: var(--spacing-default)
  }

  .footer__contact-option {
    font-size: 2rem;
  }

  .footer__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-secondary);
    font-size: .8rem;
    width: 100%;
    padding: 8px 0;
    margin: 24px 0 0;
  }

  .footer__logo--small.text-logo {
    font-size: 0.8rem;
    margin-left: 4px;
  }
</style>
